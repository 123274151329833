// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-class {
  width: 100% !important;
  margin: auto !important;
}

.tablet-class {
  width: 70% !important;
  margin: auto !important;
}

.desktop-class {
  width: 50% !important;
  margin: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,uBAAA;AACJ;;AACA;EACI,qBAAA;EACA,uBAAA;AAEJ;;AAAA;EACI,qBAAA;EACA,uBAAA;AAGJ","sourcesContent":[".mobile-class{\n    width: 100% !important;\n    margin: auto !important;\n}\n.tablet-class{\n    width: 70% !important;\n    margin: auto !important;\n}\n.desktop-class{\n    width: 50% !important;\n    margin: auto !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
