import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-image-modal',
  templateUrl: './view-image-modal.component.html',
  styleUrls: ['./view-image-modal.component.scss'],
})
export class ViewImageModalComponent implements OnInit {
  @Input() images: any[] = [];
  slideOptions = {
    initialSlide: 0,
    zoom: true
  };
  constructor(private modalCOntroller: ModalController) { }

  ngOnInit() { }

  onCancel() {
    this.modalCOntroller.dismiss();
  }

}
