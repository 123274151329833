import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AccountService } from '../_services/account.service';
import { SharedService } from '../_services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class IsLoggedInGuard implements CanActivate {
    constructor(private accountService: AccountService, private sharedService: SharedService) {
    }

    async canActivate() {
        const token = localStorage.getItem('token');
        if (token) {
            let re = await this.getAccount();
            return re ? false : true;
        } else {
            return true;
        }
    }

    async getAccount() {
        let re = (await this.accountService.getAccount(true).toPromise()).data.is_Onboarded
        return re;
    }

}
