import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private apiUrl = `${environment.API_URL}/Authentication`;
  email = '';
  constructor(private http: HttpClient) {}

  appleSignin(userId: string, identityToken: string, firstName: string, lastName: string, email: string): Observable<any> {

    const params: FormData = new FormData();
    params.append('userId', userId);
    params.append('identityToken', identityToken);
    params.append('firstName', firstName);
    params.append('lastName', lastName);
    params.append('email', email);

    return this.http
      .post(this.apiUrl + '/apple-signin', params)
      .pipe(
        map((response: any) => {
          const ret = response;
          if (ret) {
            return ret;
          }
        })
      );
  }

  googleSignIn(userId: string, identityToken: string, firstName: string, lastName: string, email: string): Observable<any> {

    const params: FormData = new FormData();
    params.append('userId', userId);
    params.append('identityToken', identityToken);
    params.append('firstName', firstName);
    params.append('lastName', lastName);
    params.append('email', email);

    return this.http
      .post(this.apiUrl + '/google-signin', params)
      .pipe(
        map((response: any) => {
          const ret = response;
          if (ret) {
            return ret;
          }
        })
      );
  }

  validateEmail(email: string): Observable<any> {
    let formData = new FormData();
    formData.append('value', email);
    return this.http.post<any>(this.apiUrl + '/Validate', formData).pipe(
      map((ret) => {
        if (ret && ret.data) {
          this.email = email;
        }
        return ret;
      })
    );
  }

  validateToken(code: any): Observable<any> {
    let formData = new FormData();
    formData.append('value', this.email);
    formData.append('code', code);
    return this.http.post<any>(this.apiUrl + '/token', formData);
  }

  isAuthenticated() {
    return !!localStorage.getItem('token');
  }
}
