import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalController, ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Share } from '@capacitor/share';
import { ToastService } from '../_services/toast.service';
import { Platform } from '@ionic/angular';
import { Observable, Subject, from } from 'rxjs';
import { ViewImageModalComponent } from '../shared/components/view-image-modal/view-image-modal.component';
import { concatMap, map, toArray } from 'rxjs/operators';
import { PDFDocument } from 'pdf-lib';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public token: string = '';
  public deviceToken: string = '';
  public latLng$: Subject<any> = new Subject();
  constructor(
    private router: Router,
    private toastController: ToastController,
    private modalController: ModalController,
    private platform: Platform,
    private toaster: ToastService,
    private http: HttpClient
  ) { }

  async onViewProfilePhoto(images:any[]) {
    const modal = await this.modalController.create({
      backdropDismiss: true,
      showBackdrop: true,
      canDismiss:true,
      // swipeToClose: true,
      mode: 'ios',    
      component: ViewImageModalComponent,
      componentProps: { images: images}
    });
    modal.present();
  }

  navigateToNextStep(onboardStep: number): void {
    switch (onboardStep) {
      case -1:
        this.router.navigate(['/tabs']);
        break;
      case 0:
        this.router.navigate(['/onboard']);
        break;
      case 1:
        this.router.navigate(['/onboard-name']);
        break;
      case 2:
        this.router.navigate(['/onboard-birthday']);
        break;
      case 3:
        this.router.navigate(['/onboard-gender']);
        break;
      case 4:
        this.router.navigate(['/onboard-username-type']);
        break;
      case 5:
        this.router.navigate(['/onboard-genres']);
        break;
      case 6:
        this.router.navigate(['/onboard-repritoir']);
        break;
      case 7:
        this.router.navigate(['/onboard-skill-level']);
        break;
      case 8:
        this.router.navigate(['/onboard-pictures']);
        break;
      case 9:
        this.router.navigate(['/onboard-bio']);
        break;
      case 10:
        this.router.navigate(['/onboard-music']);
        break;
      case 11:
        this.router.navigate(['/onboard-social-accounts']);
        break;
      case 12:
        this.router.navigate(['/onboard-location']);
        break;
      case 13:
        this.router.navigate(['/onboard-company']);
        break;
      default:
        this.router.navigate(['/tabs']);
        break;
    }
  }

  async showToastMessage(message: string, color: string = 'secondary',
  ) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1000,
      color: color,
      position: 'top',
      cssClass: 'toaster',
    });
    toast.present();
  }


  async share(val: string) {
    const url = document.URL;
    const isHTTPS = url.startsWith('http:');
    const currentUrl = val;

    if (
      ((
        this.platform.is('pwa') ||
        this.platform.is('mobileweb') ||
        this.platform.is('ios') ||
        this.platform.is('android'))
        && navigator.share)
    ) {
      Share.share({
        url: val,
      })
        .then((response) => {
        })
        .catch((err) => {
        });
    } else {
      const copyText = document.createElement('textarea');
      copyText.style.position = 'fixed';
      copyText.style.left = '0';
      copyText.style.top = '0';
      copyText.style.opacity = '0';
      copyText.value = currentUrl;
      document.body.appendChild(copyText);
      copyText.select();
      navigator.clipboard.writeText(copyText.value);
      document.body.removeChild(copyText);
      this.toaster.createAndShow('Your link has been copied', 'success');
      try {
        window.parent.postMessage(val, '*');
      } catch (error) {
        
      }
    }
  }

  async copyLink(link: string) {
    const copyText = document.createElement('textarea');
    copyText.style.position = 'fixed';
    copyText.style.left = '0';
    copyText.style.top = '0';
    copyText.style.opacity = '0';
    copyText.value = link;
    document.body.appendChild(copyText);
    copyText.select();
    navigator.clipboard.writeText(copyText.value);
    document.body.removeChild(copyText);
    this.toaster.createAndShow('Your link has been copied', 'success')
  }

  mergePDFs(pdfUrls:any): void {
    this.fetchPdfs(pdfUrls).subscribe({
      next: (pdfArrayBuffers: Uint8Array[]) => {
        this.mergePdfDocuments(pdfArrayBuffers).then((mergedPdfBytes) => {
          this.downloadPdf(mergedPdfBytes, 'merged_document.pdf');
        });
      },
      error: (err) => {
        console.error('Error fetching PDFs:', err);
      },
    });
  }

  fetchPdfs(urls: string[]): Observable<Uint8Array[]> {
    const requests = urls.map((url) => this.http.get(url, { responseType: 'arraybuffer'}));
    return from(requests).pipe(
      concatMap((obs) => obs),
      map((buffer) => new Uint8Array(buffer)),
      toArray() // Convert results to an array
    );
  }

  async mergePdfDocuments(pdfBuffers: Uint8Array[]): Promise<Uint8Array> {
    const mergedPdf = await PDFDocument.create();
    for (const pdfBuffer of pdfBuffers) {
      const sourcePdf = await PDFDocument.load(pdfBuffer);
      const copiedPages = await mergedPdf.copyPages(
        sourcePdf,
        sourcePdf.getPageIndices()
      );
      copiedPages.forEach((page) => mergedPdf.addPage(page));
    }
    return mergedPdf.save();
  }

  downloadPdf(pdfBytes: Uint8Array, filename: string): void {
    const blob:any = new Blob([pdfBytes], { type: 'application/pdf' });
    // saveAs(blob, filename);
    // let objectURL = URL.createObjectURL(blob);
    var fileURL = window.URL.createObjectURL(blob);
    let tab:any = window.open();
    tab.location.href = fileURL;
  }
}

export function truncateChar(text: string, enable: boolean, charlimit = 180): string {
  let ret = '';
  if (enable) {
    if (!text || text.length <= charlimit) {
      return text;
    }
    ret = text.substring(0, charlimit);
  } else {
    ret = text;
  }
  return ret;
}

export function getFormValidationErrors(form: FormGroup) {
  const result = [];
  Object.keys(form.controls).forEach(key => {
    const controlErrors: ValidationErrors = form.get(key).errors;
    if (controlErrors) {
      Object.keys(controlErrors).forEach(keyError => {
        result.push({
          'control': key,
          'error': keyError,
          'value': controlErrors[keyError]
        });
      });
    }
  });
  return result[0].control.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
}