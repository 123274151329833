import { Platform } from '@ionic/angular';
import { Component, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { AppService } from './_services/app.service';
import { AnalyticsService } from './_services/analytics.service';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AppEventService } from './_services/app-event.service';
import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isBrowser: boolean;
  public isRunningOnBrowser:boolean=false;
  public isShardShow:boolean=false;
  private windowScreenWidth:number;
  constructor(private platform: Platform, 
		private analyticsService: AnalyticsService,
    private router:Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private appEventService:AppEventService
    ) {
      this.isRunningOnBrowser = isPlatformBrowser(this.platformId);
      this.windowScreenWidth = window.innerWidth;
      this.appEventService.showTicketDetailInfo$.subscribe((res:any)=>{
        this.isShardShow=res['isShared']
      })

    setTimeout(() => {
      // SET PLATFORM
      AppService.PLATFORM = Capacitor.getPlatform();
      this.initializeApp();
      SplashScreen.hide();
    }, 2000);

    this.router.events.subscribe((route:any)=>{
      if(route instanceof NavigationEnd){
        window.parent.postMessage({ type:'ROUTE_CHANGE',route:route.urlAfterRedirects},"*")
      }
    })
    this.isBrowser = platform.is('desktop');
    this.analyticsService.init();
  }

  initializeApp(): void {
    GoogleAuth.initialize({
      clientId: '669222940423-v4l5fsofe1lr6aeemiueqee18pqg4id3.apps.googleusercontent.com',
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.updateWindowWidth();
  }

  updateWindowWidth(): void {
    this.windowScreenWidth = window.innerWidth;
  }
  getDynamicClass(): any {
    return {
      'mobile-class': this.isRunningOnBrowser && this.isShardShow &&  this.windowScreenWidth < 768,
      'tablet-class': this.isRunningOnBrowser && this.isShardShow && this.windowScreenWidth >= 768 && this.windowScreenWidth < 1024,
      'desktop-class':this.isRunningOnBrowser && this.isShardShow &&  this.windowScreenWidth >= 1024
    };
  }
}
