import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SocialHandles } from '../_types/social-handles.type';
import { AppService } from './app.service';
import { AuthenticationService } from './authentication.service';
import { UtilityService } from './utility.service';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	private apiUrl = `${environment.API_URL}/Account`;
	public account: any;
	public infoUpdated: BehaviorSubject<boolean> = new BehaviorSubject(null);

	constructor(private http: HttpClient, private authService: AuthenticationService) {}

	getAccount(force: boolean = false): Observable<any> {
		if (!this.authService.isAuthenticated()) return of(null);
		if (this.account && !force) {
			return of(this.account);
		}
		return this.http.get<any>(this.apiUrl).pipe(
			map((res) => {
				if (res) {
					localStorage.setItem('user', JSON.stringify(res.data));
					this.account = res;
					AppService.user = res.data;
					return res;
				}
			}),
		);
	}

	getMyPosts(type: string, page = 1, count = 50): Observable<any> {
		let params = new HttpParams();
		if (type) params = params.append('type', type);
		return this.http.get<any>(`${this.apiUrl}/my-posts/${type}?page=${page}&count=${count}&type=${type}`);
	}

	getMusics(): Observable<any> {
		return this.http.get<any>(`${this.apiUrl}/get-musics`);
	}

	getMyUpcomingEvents(page = 1, count = 50, search?: string,timeZone:any=null,localDateTime:any=null,): Observable<any> {
		let qp = new HttpParams();
        if (search) qp = qp.append('search', search)
        qp = qp.appendAll({ page: page, count: count ,timeZone:timeZone,requestTime:localDateTime})
		return this.http.get<any>(`${this.apiUrl}/my-upcoming-events`,{params:qp});
	}

	getMyPastEvents(page = 1, count = 50, search?: string,timeZone:any=null,localDateTime:any=null,): Observable<any> {
		let qp = new HttpParams();
        if (search) qp = qp.append('search', search)
        qp = qp.appendAll({ page: page, count: count,timeZone:timeZone,requestTime:localDateTime })
		return this.http.get<any>(`${this.apiUrl}/my-past-events`,{params:qp});
	}

	getAccountStat(type: string): Observable<any> {
		return this.http.get<any>(`${environment.API_URL}/account-stat/${type}`);
	}

	getMyFollowers(page = 1, count = 30, search?: string): Observable<any> {
		let params = new HttpParams();
		if (search) params = params.append('search', search);
		return this.http.get<any>(`${this.apiUrl}/my-followers?page=${page}&count=${count}`);
	}

	getFollowed(page = 1, count = 30, search?: string): Observable<any> {
		let params = new HttpParams();
		if (search) params = params.append('search', search);
		return this.http.get<any>(`${this.apiUrl}/my-followings?page=${page}&count=${count}`);
	}

	getBlockedUsers(page = 1, count = 30, search?: string): Observable<any> {
		let params = new HttpParams();
		if (search) params = params.append('search', search);
		return this.http.get<any>(`${this.apiUrl}/my-blocks?page=${page}&count=${count}`);
	}

	updateAccount(body: any): Observable<any> {
		return this.http.put<any>(this.apiUrl, body);
	}

	addMedia(media: any): Observable<any> {
		// let fd = UtilityService.jsonToFormData(media);
		return this.http.put<any>(this.apiUrl + '/add-media', media);
	}

	removeMedia(mediaId: any): Observable<any> {
		return this.http.put<any>(`${this.apiUrl}/remove-media/${mediaId}`, {});
	}

	updateSocialHandles(body: SocialHandles): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/update-social-handles', body);
	}

	onboard(onboardData: FormData): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/v2/onboard', onboardData).pipe(
			map((res) => {
				if (res && res.success) {
					this.account = res;
				}
				return res;
			}),
		);
	}

	onboardAccount(onboardData: any): Observable<any> {
		const fd = UtilityService.jsonToFormData(onboardData);
		return this.http.post<any>(this.apiUrl + '/onboard', fd).pipe(
			map((res) => {
				if (res && res.success) {
					this.account = res;
				}
				return res;
			}),
		);
	}

	updateProfilePhoto(fd: FormData): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/update-picture', fd);
	}

	updateDeviceToken(payload: any) {
		return this.http.put(`${this.apiUrl}/register-device`, payload);
	}

	updateWelcome(): Observable<any> {
		return this.http.put<any>(`${this.apiUrl}/update-welcome`, {});
	}

	signout(deviceToken: string): Observable<any> {
		const fd = new FormData();
		fd.append('deviceToken', deviceToken);
		return this.http.post<any>(this.apiUrl + '/signout', fd).pipe(
			map((res) => {
				this.account = null;
				return res;
			}),
		);
	}

	delete(): Observable<any> {
		return this.http.post<any>(this.apiUrl + '/delete', {});
	}
}
