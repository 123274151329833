export class UtilityService {
  public static jsonToFormData(
    model: any,
    form: FormData = null,
    namespace = ''
  ): FormData {
    let formData = form || new FormData();
    let formKey = '';

    for (let propertyName in model) {
      if (!model.hasOwnProperty(propertyName) || !model[propertyName]) continue;
      let formKey = namespace ? `${namespace}.${propertyName}` : propertyName;

      if (model[propertyName] instanceof Date)
        formData.append(formKey, model[propertyName].toISOString());
      else if (model[propertyName] instanceof Array) {
        model[propertyName].forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`;
          if (typeof element !== 'object') {
            formData.append(tempFormKey, element);
          } else {
            this.jsonToFormData(element, formData, tempFormKey);
          }
        });
      } else if (
        typeof model[propertyName] === 'object' &&
        !(model[propertyName] instanceof File)
      )
        this.jsonToFormData(model[propertyName], formData, formKey);
      else if (
        typeof model[propertyName] === 'object' &&
        model[propertyName] instanceof File
      ) {
        formData.append(formKey, model[propertyName]);
      } else formData.append(formKey, model[propertyName]);
    }
    return formData;
  }
}
