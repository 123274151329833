import { Injectable } from '@angular/core';
import { UserSubscription } from '../_types/subscription.types';

@Injectable()
export class AppService {
  public static restore_required = false;
  public static revenueCat_subscriberId = ''; // For RevenueCat
  public static subscriptions: UserSubscription[] = [];
  public static permissions: any[] = []; // If you need this
  public static get activeSubscription(): UserSubscription {
    return this.subscriptions.find((x) => x.isActive);
  }
  public static user: any;
  public static revenueCat_initialized = false; // For RevenueCat
  public static PLATFORM = '';
  public static store = 'stripe'; // Adjust if needed for RevenueCat

  public static get isPro(): boolean {
    if (this.subscriptions.length < 1) return false;

    const lastExpirySub = this.subscriptions.sort(
      (a, b) => new Date(b.expiresOn).getTime() -  new Date(a.expiresOn).getTime()
    )[0];

    if (!lastExpirySub) return false;

    return !this.isExpired(lastExpirySub.expiresOn);
  }

  public static get canSendMessage(): boolean {
    if (this.user && this.user.role.toLowerCase() === 'artist') {
      return this.isPro;
    }

    return true;
  }

  static isExpired(date: Date) {
    const t = new Date(date);
    const now = new Date();

    return now.getTime() > t.getTime();
  }
}
