import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HeaderInterceptor } from "./_interceptors/header.interceptor";
import { Camera } from "@awesome-cordova-plugins/camera/ngx";
import { File } from "@awesome-cordova-plugins/file/ngx";
import { Chooser } from "@awesome-cordova-plugins/chooser/ngx";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { environment } from "src/environments/environment";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";
import { Badge } from "@awesome-cordova-plugins/badge/ngx";
import LogRocket from "logrocket";
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
// import { NgChartsModule } from "ng2-charts";
// import { ApplePay } from "@ionic-native/apple-pay/ngx";
import { DatePipe } from "@angular/common";

LogRocket.init(environment.logRocketAppId);

// LogRocket.getSessionURL(sessionURL => {
//   console.log(`LogRocket session: ${sessionURL}`);
// });

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const linkRenderer = renderer.link;

  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a role="link" tabindex="0" target="_blank" rel="nofollow noopener noreferrer" ');
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: "",
      mode: "ios",
    }),
    MarkdownModule.forRoot({
      loader: HttpClientModule,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    // NgChartsModule,
    AngularFireAuthModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    Camera,
	  // ApplePay,
    File,
    Chooser,
    Badge,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
