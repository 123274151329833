// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img {
  height: 480px;
  width: auto;
}

.close {
  color: #ffffff;
  font-size: 24px;
  padding: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/view-image-modal/view-image-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;AACJ;;AAEA;EACI,cAAA;EACA,eAAA;EACA,YAAA;AACJ","sourcesContent":[".img{\n    height: 480px;\n    width:auto;\n}\n\n.close{\n    color:#ffffff;\n    font-size: 24px;\n    padding: 8px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
