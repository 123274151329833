import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AnalyticsService {
	analyticsEnabled = true;
	identified = false;
	private activeRouteInfo = new Subject<{routePathName:string}>();
	activeRoute$=this.activeRouteInfo.asObservable();
	constructor(private router: Router) { }

	async init() {
		mixpanel.init(environment.mixpanel, { debug: true });
	}

	track(ev: any, type: any = {}) {
		mixpanel.track(ev, type);
	}
	identify(userData: any) {
		if (!this.identified) {
			mixpanel.identify(userData.email);
			mixpanel.people.set({
				$first_Name: userData.first_Name,
				$last_Name: userData.last_Name,
				$username: userData.username,
				$email: userData.email,
				$role: userData.role,
				$skill_Level: userData.skillLevel,
				$location: userData.formattedString,
				$genres: userData.genres,
			});
			this.identified = true;
		}
	}
	setActiveRoute(params:{routePathName:string}):void{
		if(params)this.activeRouteInfo.next(params)
	}
}
