import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppService } from '../_services/app.service';
import { SharedService } from '../_services/shared.service';
import { SubscriptiontService } from '../_services/subscription.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private subscriptionService: SubscriptiontService,
    private sharedService: SharedService
  ) {}
  async canActivate(): Promise<boolean> {
    const token = localStorage.getItem('token');
    this.sharedService.token = token;
    if (token) {
      this.sharedService.token = token;

      AppService.user = JSON.parse(localStorage.getItem('user'));
      // LOAD SUBSCRIPTIONS IF USER IS PRO BUT AT RUNTIME SUBSCRIPTIONS ARE NOT LOADED
      if (
        AppService.user &&
        AppService.user.role &&
        AppService.user.role.toLowerCase() === 'artist' &&
        AppService.user.isPro &&
        AppService.subscriptions.length < 1
      ) {
        AppService.subscriptions = await this.subscriptionService
          .getAllByUser()
          .toPromise();
      }
      return true;
    } else {
      this.sharedService.token = '';
      this.router.navigate(['authentication']);
      return false;
    }
  }
}
