import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, of } from "rxjs";

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.body?.suffix) {
      return next.handle(request);
    }
    if(request.url.includes("https://gigmor.azureedge.net/tickets")){
      return next.handle(request);
    }
    const accessToken = localStorage.getItem("token")!;
    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: "Bearer " + accessToken,
        },
      })
    );
  }
}
